<template>
  <div>
    <b-card title="User Details">
      <validation-observer ref="basicValidation">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group label="User Name">
                <b-form-input v-model="User.name" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Email">
                <b-form-input type="email" v-model="User.email" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Password"
                class="password-label"
                disabled="true"
              >
                <b-form-input
                  v-model="User.password"
                  type="password"
                  class="password-label"
                />
              </b-form-group>
            </b-col>
            <b-col :cols="User.role === 'Resort-Manager' ? 3 : 6">
              <b-form-group label="Role">
                <b-form-select v-model="User.role" :options="roles" />
              </b-form-group>
            </b-col>
            <b-col v-if="User.role === 'Resort-Manager'" cols="3">
              <b-form-group label="Resort Staffs">
                <v-select
                  :reduce="(name) => name._id"
                  v-model="User.reportingStaffs"
                  multiple
                  label="name"
                  :options="bookingStaffs"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Gender">
                <b-form-select
                  v-model="User.gender"
                  placeholder="Gender"
                  :options="genders"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Phone">
                <b-form-input
                  v-model="User.phone"
                  type="number"
                  placeholder="Phone"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="City">
                <b-form-input v-model="User.city" placeholder="City" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="State">
                <b-form-input v-model="User.state" placeholder="State" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Country">
                <b-form-input v-model="User.country" placeholder="Country" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="PinCode">
                <b-form-input v-model="User.pincode" placeholder="Pincode" />
              </b-form-group>
            </b-col>
            <b-col cols="6" v-if="User.role!=='User'">
              <b-form-group label="Sub Merchant Id">
                <b-form-input v-model="User.subMerchantId" placeholder="Sub Merchant Id" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card>
      <b-col class="align-self-center">
        <b-button @click="updateUser" size="lg" block variant="primary"
          >Update User</b-button
        >
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { getUserToken } from "@/auth/utils";
import vSelect from "vue-select";

import {
  BCard,
  BRow,
  BCol,
  BForm,
  BTabs,
  BTab,
  BFormInput,
  BFormGroup,
  BButton,
  BFormTextarea,
  BFormFile,
  BCardText,
  BFormSelect,
} from "bootstrap-vue";
import axios from "axios";
export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BFormTextarea,
    BFormFile,
    BCardText,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      userID: "",
      User: {
        name: "",
        email: "",
        password: "",
        newpassword: "",
        phone: "",
        role: "User",
        gender: "male",
        city: "",
        state: "",
        country: "",
        pincode: "",
        reportingStaffs: [],
      },
      bookingStaffs: [],
      roles: [
        { value: "User", text: "User" },
        { value: "Agent", text: "Agent" },
        { value: "Resort-Manager", text: "Resort Manager" },
        { value: "Resort-Staff", text: "Resort Staff" },
        { value: "Sr-Booking-Officer", text: "Sr. Booking Officer" },
        { value: "Booking-Staff", text: "Booking Staff" },
      ],
      genders: [
        { value: "male", text: "Male" },
        { value: "female", text: "Female" },
        { value: "transgender", text: "Transgender" },
        { value: "otherr", text: "Not Prefer to say" },
      ],
    };
  },
  mounted() {
    this.getPage();
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    getPage() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/org/user/get/${this.$route.params.slug}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.userID = response.data._id;
          this.User = response.data;
          this.getResortStaffs();
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Error while adding the User!");
        });
    },
    updateUser() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/org/user/update`,
          { userID: this.userID, updates: this.User },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.makeToast(
            "success",
            "Success",
            `${response.data.name} has been updated successfully!`
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Error while updating the User!");
        });
    },
    getResortStaffs() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/org/user/getUsersByRole?role=Resort-Staff`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.bookingStaffs = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.password-label[disabled] {
  color: gray;
}

.password-label:hover {
  color: red;
  cursor: not-allowed;
}
</style>